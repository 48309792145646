<template>
  <div style="margin: -10px">
    <LineChart
      v-if="chartType === 'line'"
      :chart-data="data"
      :options="data.options"
      :css-classes="chartCSS"
    />
    <BarChart
      v-if="chartType === 'bar'"
      :chart-data="data"
      :options="data.options"
      :css-classes="chartCSS"
    />
    <!-- <div class="legend">
      <div class="legend-item">
        <span
          class="legend-color"
          :style="{ backgroundColor: props.line_color }"
        ></span>
        {{ measured_data }}
      </div>
      <div class="legend-item" v-if="is_there_forecast">
        <span class="legend-color forecast-color"></span> {{ forecasted_data }}
      </div>
    </div> -->
  </div>
</template>
<script setup>
import { onMounted } from "vue";
import { ref, computed, reactive } from "vue";
import { LineChart, BarChart } from "vue-chart-3";
import "chartjs-adapter-moment";
import i18n from "@/i18n";
import {
  Chart,
  LineController,
  BarController,
  PointElement,
  LineElement,
  BarElement,
  CategoryScale,
  LinearScale,
  TimeScale,
  TimeSeriesScale,
} from "chart.js";

Chart.register(
  LineController,
  BarController,
  PointElement,
  LineElement,
  BarElement,
  CategoryScale,
  LinearScale,
  TimeScale,
  TimeSeriesScale
);

const measured_data = ref(i18n.global.t("lake.graphlabels.measured_data"));
const forecasted_data = ref(i18n.global.t("lake.graphlabels.forecasted_data"));

const props = defineProps({
  title: {
    type: String,
    default: "",
  },
  chartCSS: {
    type: String,
    default: "chart-container",
  },
  label: {
    type: Object,
    required: true,
  },
  chartData: {
    type: Array,
    required: true,
  },
  forecastChartData: {
    type: Array,
    required: false,
  },
  start_date: {
    type: String,
    default: "",
  },
  end_date: {
    type: String,
    default: "",
  },
  interval: {
    type: String,
    default: "day",
  },
  unit: {
    type: String,
    default: "",
  },
  line_color: {
    type: String,
    default: "",
  },
  point_color: {
    type: String,
    default: "",
  },
  labelColor: {
    type: String,
    default: "rgba(255, 255, 255, 1)",
  },
  minLimit: {
    type: Number,
    default: 0,
  },
  maxLimit: {
    type: Number,
    default: 100,
  },
  chartType: {
    type: String,
    default: "line",
  },
  hasForecast: {
    type: Boolean,
    default: false,
  },
  shouldHaveForecast: {
    type: Boolean,
    default: false,
  },
});

const final_date = computed(() => {
  if (props.hasForecast) {
    const endDate = new Date();
    endDate.setDate(endDate.getDate() + 7);
    return endDate.toISOString().split("T")[0]; // Formatting back to YYYY-MM-DD
  } else {
    return props.end_date;
  }
});

const mergedDates = computed(() => {
  const dates = new Set(props.chartData.map((element) => element.date));
  if (props.forecastChartData) {
    props.forecastChartData.forEach((element) => dates.add(element.date));
  }
  return Array.from(dates).sort((a, b) => new Date(a) - new Date(b));
});

const is_there_forecast = computed(() => {
  // check if there is data in forecasts that have data != null
  if (props.hasForecast) {
    return props.forecastChartData.some((element) => element.data !== null);
  } else {
    return false;
  }
});

const data = computed(() => {
  const datasets = [
    {
      label: measured_data.value,
      data: mergedDates.value.map((date) => {
        const dataPoint = props.chartData.find(
          (element) => element.date === date
        );
        return dataPoint ? dataPoint.data : null;
      }),
      backgroundColor: props.point_color,
      borderColor: props.line_color,
      color: "#FFF",
      backdropColor: "#FFF",
      borderWidth: 1,
    },
  ];

  if (props.forecastChartData) {
    datasets.push({
      label: forecasted_data.value,
      data: mergedDates.value.map((date) => {
        const dataPoint = props.forecastChartData.find(
          (element) => element.date === date
        );
        return dataPoint ? dataPoint.data : null;
      }),
      backgroundColor: "#FFB700",
      borderColor: "#FFB700",
      color: "#FFF",
      backdropColor: "#FFF",
      borderWidth: 1,
    });
  }

  return {
    backgroundColor: "#000",
    labels: mergedDates.value,
    datasets,
    options: {
      spanGaps: true,
      responsive: true,
      maintainAspectRatio: true,
      barPercentage: 0.9,
      categoryPercentage: 0.9,
      barThickness: "flex",
      scales: {
        x: {
          grid: {
            color: "rgba(255, 255, 255, 0.05)",
            offset: false,
          },
          type: "time",
          distribution: "linear",
          bounds: "ticks",
          offset: false,
          min: props.start_date,
          max: final_date.value,
          time: {
            unit: props.interval,
            tooltipFormat: "DD/MM/YYYY HH:mm",
          },
          ticks: {
            color: props.labelColor,
          },
        },
        y: {
          title: {
            display: true,
            text: props.unit,
            color: props.labelColor,
          },
          grid: {
            color: "rgba(255, 255, 255, 0.05)",
          },
          ticks: {
            color: props.labelColor,
          },
          min: props.minLimit,
          max: props.maxLimit,
        },
      },
      plugins: {
        title: {
          display: true,
          text: props.title,
          color: "#FFF",
          font: {
            family: "Poppins",
            size: 16,
          },
        },
        legend: {
          display: true,
          labels: {
            color: "#FFF",
            font: {
              family: "Poppins",
              size: 9,
            },
          },
        },
      },
    },
  };
});
</script>
<style lang="scss">
.chart-container {
  margin: auto;
  margin-bottom: 2px;
  width: 85%;
  min-width: 300 !important;
  height: 300px;
}
.legend {
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
  font-size: 0.8rem;
}

.legend-item {
  display: flex;
  align-items: center;
  margin-right: 20px;
}

.legend-color {
  display: inline-block;
  width: 12px;
  height: 12px;
  margin-right: 5px;
  border-radius: 2px;
}

.actual-color {
  background-color: rgba(45, 108, 196, 0.6);
}

.forecast-color {
  background-color: #ffb700;
}
</style>
