import { reactive } from "vue";
import axios from "axios";
import { useRoute } from "vue-router";
import moment from "moment";

var data = reactive({
  data: [],
});
var future_data = reactive({
  data: [],
});
let format = "";

function showNewDate(date) {
  return new Date(Date.parse(date));
}

export function formatDateToUserLocal(date) {
  if (!date) return "";
  let parsedDate;
  // Check if the date is in DD-MM-YYYY format using regex
  const ddMMyyyyPattern = /^\d{2}-\d{2}-\d{4}$/;
  if (ddMMyyyyPattern.test(date)) {
    // Parse the DD-MM-YYYY format date string
    const [day, month, year] = date.split("-");
    parsedDate = new Date(`${year}-${month}-${day}T00:00:00Z`);
  } else {
    parsedDate = new Date(date);
  }

  const isoDate = parsedDate.toISOString();
  const format = "YYYY-MM-DD HH:mm:ss";
  return moment.utc(isoDate).local().format(format);
}

const absoluteMin = {
  water_temperature: -5,
  pressure: 700,
  level: -2,
  at_temperature: -5,
  precipitation: -1,
};
const absoluteMax = {
  water_temperature: 15,
  pressure: 1100,
  level: 150,
  at_temperature: 15,
  precipitation: 100,
};

function defineMinAndMax(dataArray, dataArray2, minInterval, abs_min, abs_max) {
  // Combine data from both arrays and filter out None values
  const combinedData = dataArray.concat(dataArray2).filter(function (o) {
    return o.data !== null && o.data !== undefined;
  });

  // Extract the data values from the combined array
  const dataValues = combinedData.map(function (o) {
    return o.data;
  });

  // Calculate the initial min and max from the combined data
  let min = Math.min.apply(Math, dataValues);
  let max = Math.max.apply(Math, dataValues);

  // Ensure the range meets the minimum interval
  if (max - min < minInterval) {
    const difference = minInterval - (max - min);
    max = max + difference / 2;
    min = min - difference / 2;

    // Adjust if max exceeds abs_max
    if (max > abs_max) {
      const max_dif = max - abs_max;
      max = abs_max;
      min = min - max_dif;
    }
    // Adjust if min is below abs_min
    else if (min < abs_min) {
      const min_dif = abs_min - min;
      min = abs_min;
      max = max + min_dif;
    }
  }

  // Final adjustments to min and max
  min = Math.floor(min);
  max = Math.ceil(max);

  return { min, max };
}

export var lakemeasurements = reactive({
  loaded: false,
  nodata: false,
  interval: "",
  start_date: "",
  end_date: "",
  water_level: [],
  water_temperature: [],
  atmospheric_pressure: [],
  atmospheric_temperature: [],
  precipitation: [],
  alert_status: [],
  last_data_date: "",
});

export var lakepredictions = reactive({
  noprediction: false,
  water_level: [],
  water_temperature: [],
  atmospheric_pressure: [],
  atmospheric_temperature: [],
  precipitation: [],
});

var apiUrl = "";
var apiKey = "";

if (process.env.NODE_ENV === "d") {
  apiUrl = "http://localhost:8000/api/lakemeasurements/";
  apiKey = "4hJb3MIV.T7rL0Q4w4or5dnfr9qvRNX0bG0tdqoVS";
} else {
  apiUrl = "https://www.sagaz.org/api/lakemeasurements/";
  apiKey = process.env.VUE_APP_API_KEY;
}

export const getLakeMeasurements = async (id, interval, summary, callback) => {
  data = [];
  future_data = [];
  let apiUrlwithId = "";
  if (summary === "false") {
    apiUrlwithId = `${apiUrl}${id}/${interval}/`;
  } else {
    apiUrlwithId = `${apiUrl}${id}/${interval}/summary/${summary}/`;
  }
  axios.defaults.headers.common.Authorization = `Api-Key ${apiKey}`;
  axios.defaults.params = {
    t: new Date().getTime(),
  };
  format = "YYYY-MM-DD HH:mm:ss";
  axios.get(apiUrlwithId).then((response) => {
    data = response.data.data.map((item) => ({
      ...item,
      date: moment.utc(item.date).format(),
    }));
    future_data = response.data.data.map((item) => ({
      ...item,
      date: moment.utc(item.date).format(),
    }));
    lakemeasurements.water_level = [];
    lakemeasurements.water_temperature = [];
    lakemeasurements.atmospheric_pressure = [];
    lakemeasurements.atmospheric_temperature = [];
    lakemeasurements.precipitation = [];
    lakemeasurements.alert_status = [];
    lakemeasurements.water_level_limits = {
      min: 0,
      max: 0,
    };
    lakemeasurements.water_temperature_limits = {
      min: 0,
      max: 0,
    };
    lakemeasurements.atmospheric_pressure_limits = {
      min: 0,
      max: 0,
    };
    lakemeasurements.atmospheric_temperature_limits = {
      min: 0,
      max: 0,
    };
    lakemeasurements.precipitation_limits = {
      min: 0,
      max: 0,
    };
    lakepredictions.water_level = [];
    lakepredictions.water_temperature = [];
    lakepredictions.atmospheric_pressure = [];
    lakepredictions.atmospheric_temperature = [];
    lakepredictions.precipitation = [];

    // filter the data to separate the forecast data from the real data
    data = data.filter((data) => data.is_forecast === 0);
    future_data = future_data.filter((data) => data.is_forecast === 1);

    if (data.length === 0) {
      lakemeasurements.nodata = true;
      lakemeasurements.last_data_date = formatDateToUserLocal(
        response.data.last_data_date
      );
      lakemeasurements.start_date = "";
    } else {
      lakemeasurements.nodata = false;
      lakemeasurements.start_date = formatDateToUserLocal(data[0].date);
      lakemeasurements.end_date = formatDateToUserLocal(
        data[data.length - 1].date
      );
      if (callback) {
        callback();
      }
      data.forEach((data, id) => {
        let dataDate = moment.utc(data.date); // Convert to UTC
        if (
          data.water_level >= absoluteMin.level &&
          data.water_level <= absoluteMax.level
        ) {
          lakemeasurements.water_level.push({
            id: data.id,
            date: formatDateToUserLocal(dataDate),
            data: data.water_level,
          });
        }
        if (
          data.water_temperature >= absoluteMin.water_temperature &&
          data.water_temperature <= absoluteMax.water_temperature
        ) {
          lakemeasurements.water_temperature.push({
            id: data.id,
            date: formatDateToUserLocal(dataDate),
            data: data.water_temperature,
          });
        }
        if (
          data.atmospheric_pressure >= absoluteMin.pressure &&
          data.atmospheric_pressure <= absoluteMax.pressure
        ) {
          lakemeasurements.atmospheric_pressure.push({
            id: data.id,
            date: formatDateToUserLocal(dataDate),
            data: data.atmospheric_pressure,
          });
        }
        if (
          data.atmospheric_temperature >= absoluteMin.at_temperature &&
          data.atmospheric_temperature <= absoluteMax.at_temperature
        ) {
          lakemeasurements.atmospheric_temperature.push({
            id: data.id,
            date: formatDateToUserLocal(dataDate),
            data: data.atmospheric_temperature,
          });
        }
        if (
          data.precipitation >= absoluteMin.precipitation &&
          data.precipitation <= absoluteMax.precipitation
        ) {
          lakemeasurements.precipitation.push({
            id: data.id,
            date: formatDateToUserLocal(dataDate),
            data: data.precipitation,
          });
        }
        lakemeasurements.alert_status.push({
          id: data.id,
          date: formatDateToUserLocal(dataDate),
          data: data.alert_status,
        });
      });
    }
    if (future_data.length > 0) {
      var lastDataDate = moment.utc().format(format);
      if (data.length > 0) {
        lastDataDate = moment.utc(data[data.length - 1].date).format(format);
      }
      let lastDataDateMoment = moment.utc(lastDataDate, format);

      future_data = future_data.filter((futureData) => {
        let futureDataDateMoment = moment.utc(futureData.date, format);
        return futureDataDateMoment.isAfter(lastDataDateMoment);
      });
    } else {
      future_data = future_data.filter((data) =>
        moment.utc(data.date).isAfter(moment.utc())
      );
    }
    if (future_data.length === 0) {
      lakepredictions.noprediction = true;
    } else {
      lakepredictions.noprediction = false;
      future_data.forEach((data, id) => {
        let dataDate = moment.utc(data.date); // Convert to UTC
        if (
          data.water_level >= absoluteMin.level &&
          data.water_level <= absoluteMax.level
        ) {
          lakepredictions.water_level.push({
            id: data.id,
            date: formatDateToUserLocal(dataDate),
            data: data.water_level,
          });
        }
        if (
          data.water_temperature >= absoluteMin.water_temperature &&
          data.water_temperature <= absoluteMax.water_temperature
        ) {
          lakepredictions.water_temperature.push({
            id: data.id,
            date: formatDateToUserLocal(dataDate),
            data: data.water_temperature,
          });
        }
        if (
          data.atmospheric_pressure >= absoluteMin.pressure &&
          data.atmospheric_pressure <= absoluteMax.pressure
        ) {
          lakepredictions.atmospheric_pressure.push({
            id: data.id,
            date: formatDateToUserLocal(dataDate),
            data: data.atmospheric_pressure,
          });
        }
        if (
          data.atmospheric_temperature >= absoluteMin.at_temperature &&
          data.atmospheric_temperature <= absoluteMax.at_temperature
        ) {
          lakepredictions.atmospheric_temperature.push({
            id: data.id,
            date: formatDateToUserLocal(dataDate),
            data: data.atmospheric_temperature,
          });
        }
        if (
          data.precipitation >= absoluteMin.precipitation &&
          data.precipitation <= absoluteMax.precipitation
        ) {
          lakepredictions.precipitation.push({
            id: data.id,
            date: formatDateToUserLocal(dataDate),
            data: data.precipitation,
          });
        }
      });
    }
    lakemeasurements.water_temperature_limits = defineMinAndMax(
      lakemeasurements.water_temperature,
      lakepredictions.water_temperature,
      5,
      absoluteMin.water_temperature,
      absoluteMax.water_temperature
    );
    lakemeasurements.water_level_limits = defineMinAndMax(
      lakemeasurements.water_level,
      lakepredictions.water_level,
      3,
      absoluteMin.level,
      absoluteMax.level
    );
    lakemeasurements.atmospheric_pressure_limits = defineMinAndMax(
      lakemeasurements.atmospheric_pressure,
      lakepredictions.atmospheric_pressure,
      100,
      absoluteMin.pressure,
      absoluteMax.pressure
    );
    lakemeasurements.atmospheric_temperature_limits = defineMinAndMax(
      lakemeasurements.atmospheric_temperature,
      lakepredictions.atmospheric_temperature,
      10,
      absoluteMin.at_temperature,
      absoluteMax.at_temperature
    );
    lakemeasurements.precipitation_limits = defineMinAndMax(
      lakemeasurements.precipitation,
      lakepredictions.precipitation,
      10,
      absoluteMin.precipitation,
      absoluteMax.precipitation
    );
    lakemeasurements.loaded = true;
    lakemeasurements.interval = interval;
  });

  // Convert start and end dates to local time (only if they are not null)
  if (lakemeasurements.start_date !== "") {
    lakemeasurements.start_date = formatDateToUserLocal(
      lakemeasurements.start_date
    );
  } else {
    // set to 2 weeks back from the time that is now
    lakemeasurements.start_date = formatDateToUserLocal(
      moment().subtract(2, "weeks")
    );
  }
  if (lakemeasurements.end_date !== "") {
    lakemeasurements.end_date = formatDateToUserLocal(
      lakemeasurements.end_date
    );
  } else {
    // set to now + 1 week
    lakemeasurements.end_date = formatDateToUserLocal(moment().add(1, "week"));
  }

  return {
    lakemeasurements,
    lakepredictions,
  };
};
